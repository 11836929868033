export const stripHtml = function (html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const removeBRTag = function (html) {
  return html.replace("<br>", "");
};

export const ucFirst = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lcFirst = function (string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
};