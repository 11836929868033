<template>
  <b-container>
    <b-row v-if="organization">
      <b-col
        lg="10"
        offset-lg="1"
        sm="12"
        offset-sm="0"
      >
        <b-card 
          class="hubu-bg-red"
          header-tag="header"
          :header="$t('organization.add_member')"
        >
          <b-form @submit="addUser">
            <b-row class="mt-5">
              <b-col lg="6">
                <b-form-group
                  id="fieldset-1"
                  :label="$t('auth.first_name')"
                  label-for="first-name"
                >
                  <b-form-input
                    id="first-name"
                    v-model="user.first_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="fieldset-2"
                  :label="$t('auth.last_name')"
                  label-for="last-name"
                >
                  <b-form-input
                    id="last-name"
                    v-model="user.last_name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col lg="6">
                <b-form-group
                  id="fieldset-3"
                  :label="$t('auth.email_label')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group
                  id="fieldset-4"
                  :label="ucFirst($t('role'))"
                  label-for="role"
                >
                  <b-form-select
                    id="role"
                    v-model="user.role"
                    :options="roles"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <div
                class="description"
              >
                <span v-html="getText(`role_description_${organization.subscription.toLowerCase()}`)" />
              </div>
            </b-row>

            <b-row class="m-3">
              <b-col
                lg="12"
                sm="12"
              >
                <hr />
              </b-col>
            </b-row>

            <b-row class="m-3">
              <b-col
                lg="12"
                sm="12"
                class="text-center"
              >
                {{ $t('organization.create_user_body') }}
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col
                lg="12"
                class="text-center"
              >
                <b-button
                  type="submit"
                  class="btn-red"
                >
                  {{ $t('save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as Text from "./Helpers/Text";

export default {
  name: "AddNewAccount",
  data () {
    return {
      roles: [
        { value: "Basic", text: Text.ucFirst(this.$t("basic")) },
        { value: "Admin", text: Text.ucFirst(this.$t("admin")) },
        { value: "Owner", text: Text.ucFirst(this.$t("owner")) },
      ],
    };
  },
  computed: {
    organization () {
      return this.$store.getters.getOrganization;
    },
    members () { 
      return this.$store.getters.getOrganizations?.[0]?.hats.map(hat => hat.user);
    },
    organizationId () { 
      return this.$store.getters.getOrganizations?.[0]?.id;
    },
    user () { return {
      organizationId: this.$store.getters.getOrganizations?.[0]?.id,
      first_name: "",
      last_name: "",
      email: "",
      lang: "",
      role: "",
    };},
  },
  async mounted () {
    await this.$store.dispatch("getOrganizations");
    await this.$store.dispatch("getStandardTemplates");
  },

  methods: {
    getText (str) {
      return this.$store.getters.getAppTexts[str].content;
    },
    ucFirst (str) {
      return Text.ucFirst(str);
    },

    addUser (e) {
      e.preventDefault();
      this.$store.dispatch("addUser_step_1", { ...this.user, organization: this.user.organizationId })
        .then(() => {
          this.$router.push({
            name: "ManageAccounts",
            params: { lang: this.$i18n.locale },
          });
        })
        .catch(() => {
          this.$toast.open({ message: "Please fill in all fields", type: "error" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}

.description {
  margin: 12px;
  padding: 12px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #f6f6f6;
  color: #4c4c4c;
  width: 100%;
}

</style>